.steps_num_maindiv {
  display: flex;
  width: 100%;
  min-height: 400px;
  /* max-height: 400px; */
  justify-content: space-evenly;
  background: #015998;
  align-items: center;
  padding: 2%;
}

.steps_num_img_top {
  width: 100%;
  border-radius: 10px;
  object-fit: contain;
}
.steps_gimage_div {
    display: flex;
    justify-content: center;
    height: 200px;
    width: 90%;
  }
.steps_image_div {
  display: flex;
  justify-content: center;
  height: 200px;
}
.steps_submain_div {
  width: 25%;
  height: 305px;
  margin-left: 6px;
  border-radius: 12px !important;
}

@media Screen and (max-width: 1050px) {
  .steps_num_maindiv {
    flex-direction: column;
    align-items: center;
  }
  .steps_submain_div {
    width: 83%;
    margin-bottom: 8px;
  }
  .steps_gimage_div{
    width: 59%
  }
}
