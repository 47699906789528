.circle-button {
  position: absolute;
  top: -45%;
  left: 76%;
  /* transform: translate(-50%, -50%); */
  border-radius: 50%;
  width: 70px;
  height: 70px;
  background-color: blue;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: shining 2s infinite ease-in-out;
}

@keyframes shining {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
