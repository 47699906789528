.num_maindiv {
  display: flex;
  width: 100%;
  min-height: 400px;
  /* max-height: 400px; */
  justify-content: space-evenly;
  background-image: url(numbersImgCropped.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  align-items: center;
  padding: 2%;
}

.num_img_top {
  width: 50%;
  border-radius: 10px;
}

@media Screen and (max-width: 750px) {
  .num_maindiv {
    flex-wrap: wrap;
  }
  .num_img_top {
    width: 50%;
  }
}
