.edit_cart_butons {
  position: absolute;
  left: 68%;
  bottom: 13%;
}

@media screen and (max-width: 490px) {
  .edit_cart_butons {
    left: 56%;
    bottom: 1%;
  }
}
