/* .payment_table_heads{
    font-family: 'Poppins', sans-serif;
} */

/* Default styles for larger screens */
.delivery_rate_sheet {
  background: white;
  padding: 2%;
  position: absolute;
  top: 32%;
  left: 27%;
  width: 44%;
  border-radius: 7px;
  border: 2px solid rgb(1, 89, 136);
  z-index: 2;
}

.circle {
  display: inline-block;
  width: 14px;
  height: 12px;
  border-radius: 50%;
  background-color: #015998;
  margin-left: 5px;
  color: white;
  text-align: center;
  line-height: 18px;
  font-weight: bold;
  position: relative;
  top: 2px;
  cursor: pointer;
}

.coupon_sheet {
  background: #f0f0f0;
  padding: 2%;
  position: absolute;
  top: 32%;
  left: 27%;
  width: 34%;
  border-radius: 7px;
  border: 1px solid #d0d0d0;
  z-index: 2;
  max-height: 350px;
  overflow: auto;
}

.coupon {
  position: relative;
}

.coupon:before,
.coupon:after {
  content: '';

  border-radius: 50%;
  border: '2px solid #015998';
  position: absolute;
  top: 0;
  transform: translateY(111%);
  height: 28px;
  width: 35px;
  background-color: #f0f0f0;
}

.coupon:before {
  left: -21px;
}

.coupon:after {
  right: -21px;
}

@media screen and (min-width: 769px) and (max-width: 1150px) {
  .coupon:before {
    left: -14px;
  }

  .coupon:after {
    right: -14px;
  }
}

@media screen and (max-width: 768px) {
  .delivery_rate_sheet {
    top: 86%;
    left: 6%;
    width: 88%;
  }

  .coupon_sheet {
    top: 86%;
    left: 6%;
    width: 88%;
  }

  .coupon:before,
  .coupon:after {
    width: 20px;
    height: 24px;
  }

  .coupon:before {
    left: -7px;
  }

  .coupon:after {
    right: -6px;
  }
  .font_size_ {
    font-size: 13px;
  }
  .button_font_ {
    font-size: 12px;
  }
}
