.category-filter-div {
  margin: 0 0 3% 0;
}
.breadcrumbs_content {
  background-color: #015998;
  color: white;
  border-radius: 8px;
  padding: 1%;
  border: 1px solid black;
}

.breaking-news-container {
  overflow: hidden;
  position: relative;
  width: 100%;

}

.blue_container{

  background: #015998;
  color: white;
  border-radius: 8px;
  padding: 5px 0 0 0;
  /* border: 1px solid black; */
  height: 29px;
}

.breaking-news {
  animation: scrollText 4s linear infinite;
  white-space: nowrap;
}

@keyframes scrollText {
  0% {
    transform: translateX(40%);
  }
  100% {
    transform: translateX(-91%);
  }
}
