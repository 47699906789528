.main-outer-brand-div {
  background-color: #015998;
  padding: 14px;
  border-radius: 30px;
  color: white;
  position: absolute;
  /* top: 213px; */
  z-index: 1;
  width: 120px;
  margin: 10px 0 0 10px;
  max-height: 600px;
  overflow-y: auto;
  /* transition: top 0.3s ease-out; */
}
.main-inner-brand-div {
  text-align: center;
  cursor: pointer;
}

.outer-img-brand-div {
  width: 100%;
  min-height: 50px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.outer-img-formulation-div {
  width: 100%;
  min-height: 50px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.name-brand {
  color: white;
  font-weight: bold;
}
.name-formulation {
  color: #015998;
  font-weight: bold;
}

.inner-formulation-div {
  border-radius: 30px;
  background: white;
  color: rgb(1, 89, 152);
  text-align: center;
  height: 120px;
  line-height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 28px;
  font-size: 10px;
  font-weight: bold;
  cursor: pointer;
}

@media screen and (max-width: 1200px) and (min-width: 710px) {
  .main-outer-brand-div {
    margin: 35px 0 0 -60px;
  }
}

@media screen and (max-width: 709px) {
  .main-outer-brand-div {
    margin: 35px 0 0 -123px;
  }
}
