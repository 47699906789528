.product-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.product-info p {
  margin: 0;
  margin-bottom: 5px;
  color: black;
  font-weight: bold;
}

.productImage_div {
  height: 200px;
  width: 200px;
  overflow: hidden;
}

.productImage_div img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.product--container {
  position: relative;
  display: inline-block;
  top: -195px;
  left: 64%;
}

.product--container img {
  max-width: 100%;
  height: auto;
}

.hint-pre--badge {
  position: absolute;
  top: 0;
  right: 6px;

  color: #ffffff;
  padding: 5px;
  border-radius: 50%;
}

.product-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  margin-bottom: 5px;
  color: #015998;
  font-weight: bold;
}
