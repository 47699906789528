.footer-responsive-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.items-div {
  width: 20%;
}
.footer-terms-div {
  display: flex;
  justify-content: center;
}

.responsive-image {
  width: 85%;
}
.third_box{
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 700px) {
  .footer-responsive-div {
    display: flex;

    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  .items-div {
    width: 50%;
  }

  .heganlogo-div {
    width: 100%;
  }

  .footer-terms-div {
    display: flex;
    justify-content: start;
  }

  .responsive-image {
    width: 55%;
  }

  .third_box{
    display: flex;
    justify-content: end;
  }
  
}
