.outer_vtwo_div {
  display: flex;
  justify-content: space-evenly;
  background-color: #015998;
  border: 2px solid #015998;
  border-radius: 20px;
  border-left: 2px solid #015998;
  padding: 1% 0.5% 0 1%;
}

.inner_vtwo_div {
  width: 30%;
  text-align: center;
}

.outer_bp_vtwo {
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin: auto;
}

/* .inner_bp_vtwo {
  opacity: 0;
  animation: fadeInOut 8s infinite;
  width: 100%;
} */

.fade {
  position: absolute;
  opacity: 0;
  transition: opacity 2s;
}

.fade.in {
  opacity: 1;
  position: relative;
}

.fade h5,
.fade p,
.inner_vtwo_div h4 {
  color: white;
}

@keyframes fadeInOut {
  0%,
  25% {
    opacity: 0;
  }
  50%,
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media screen and (max-width: 600px) {
  .outer_vtwo_div {
    flex-direction: column-reverse;
  }
  .inner_vtwo_div {
    width: 100%;
  }
  .outer_bp_vtwo {
    width: 80%;
  }
}

@media screen and (min-width: 601px) and (max-width: 1150px) {
  .inner_vtwo_div {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
