.outer_bop_container_one,
.outer_bop_container_two {
  display: flex;
  gap: 22px;
  justify-content: center;
  align-items: center;
}
.top-right-image {
  width: 45%;
}
@media screen and (max-width: 1150px) {
  .outer_bop_container_one {
    flex-direction: column-reverse;
  }
  .outer_bop_container_two {
    flex-direction: column;
  }
  .top-right-image {
    width: 75%;
  }
}
