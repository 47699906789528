.main_checkout_div{
    display: flex;
    flex-wrap: wrap;
}
.submain_checkout_div_one{
width: 65%;
}

.submain_checkout_div_two{
    width: 32%;
    margin-left: 30px;
}

.submain_checkout_div_three{
    width: 60%;
    /* margin: -325px 0 0 0; */
}