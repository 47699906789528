
@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon.woff2") format("woff2"),
       url("../fonts/Flaticon.woff") format("woff"),
       url("../fonts/Flaticon.ttf") format("truetype"),
       url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
	font-family: "Flaticon";
	font-style: normal;
}

.flaticon-shipped:before { content: "\f100"; }
.flaticon-money-back:before { content: "\f101"; }
.flaticon-support:before { content: "\f102"; }
.flaticon-woman:before { content: "\f103"; }
.flaticon-boss:before { content: "\f104"; }
.flaticon-friendship:before { content: "\f105"; }
.flaticon-sunglasses:before { content: "\f106"; }
.flaticon-jacket:before { content: "\f107"; }
.flaticon-sneakers:before { content: "\f108"; }
.flaticon-watch:before { content: "\f109"; }
.flaticon-necklace:before { content: "\f10a"; }
.flaticon-herbal:before { content: "\f10b"; }
.flaticon-ball:before { content: "\f10c"; }
.flaticon-pijamas:before { content: "\f10d"; }
.flaticon-scarf:before { content: "\f10e"; }
.flaticon-vintage:before { content: "\f10f"; }
.flaticon-pregnant:before { content: "\f110"; }
.flaticon-lock:before { content: "\f111"; }
.flaticon-bed:before { content: "\f112"; }
.flaticon-table:before { content: "\f113"; }
.flaticon-armchair:before { content: "\f114"; }
.flaticon-desk-lamp:before { content: "\f115"; }
.flaticon-sofa:before { content: "\f116"; }
.flaticon-chair:before { content: "\f117"; }
.flaticon-tv:before { content: "\f118"; }
.flaticon-responsive:before { content: "\f119"; }
.flaticon-camera:before { content: "\f11a"; }
.flaticon-plugins:before { content: "\f11b"; }
.flaticon-headphones:before { content: "\f11c"; }
.flaticon-console:before { content: "\f11d"; }
.flaticon-music-system:before { content: "\f11e"; }
.flaticon-monitor:before { content: "\f11f"; }
.flaticon-printer:before { content: "\f120"; }
.flaticon-fax:before { content: "\f121"; }
.flaticon-mouse:before { content: "\f122"; }