.div_img {
  background-image: url(heroBg.png);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* .info_carousel_img {
  width: 85% !important;
} */
.phone_bg {
  background-image: url(phoneBg.png);
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 0 0 15%;
  height: 99%;
}
.vid_style {
  max-height: 100%;
  object-fit: cover;
  padding: 14px;
  border-radius: 33px;
}
@media screen and (max-width: 500px) {
  .phone_bg {
    margin: 0 0 0 8%;
    height: 170px;
  }
  .vid_style {
    width: 100%;
    padding: 6px;
    border-radius: 11px;
  }
  .info_carousel_img {
    width: 100% !important;
  }
}
@media screen and (min-width: 1090px) {
  .div_img {
    height: 455px;
  }
}

@media screen and (max-width: 600px) and (min-width: 501px) {
  .phone_bg {
    margin: 0 0 0 8%;
    height: 150px;
  }
  .vid_style {
    width: 100%;

    object-fit: cover;
    padding: 4px;
    border-radius: 13px;
  }
  .info_carousel_img {
    width: 100% !important;
  }
}

@media screen and (max-width: 700px) and (min-width: 601px) {
  .phone_bg {
    margin: 0 0 0 8%;
    height: 200px;
  }
  .vid_style {
    width: 100%;

    object-fit: cover;
    padding: 6px;
    border-radius: 14px;
  }
}

@media screen and (max-width: 800px) and (min-width: 701px) {
  .phone_bg {
    margin: 0 0 0 8%;
    height: 250px;
  }
  .vid_style {
    width: 100%;

    object-fit: cover;
    padding: 8px;
    border-radius: 14px;
  }
}

@media screen and (max-width: 900px) and (min-width: 801px) {
  .phone_bg {
    margin: 0 0 0 8%;
    height: 300px;
  }
  .vid_style {
    width: 100%;

    object-fit: cover;
    padding: 10px;
    border-radius: 15px;
  }
}

@media screen and (max-width: 1000px) and (min-width: 901px) {
  .phone_bg {
    margin: 0 0 0 8%;
    height: 350px;
  }
  .vid_style {
    width: 100%;

    object-fit: cover;
    padding: 11px;
    border-radius: 21px;
  }
}
@media screen and (max-width: 1100px) and (min-width: 1001px) {
  .phone_bg {
    margin: 0 0 0 8%;
    height: 400px;
  }
  .vid_style {
    width: 100%;

    object-fit: cover;
    padding: 13px;
    border-radius: 23px;
  }
}
