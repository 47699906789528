.categories-outer-div {
  display: flex;
}

.categories-inner-div {
  width: 16.67%;
}
.categories-outer-div-mp {
  width: 100%; /* Adjust the width as per your design */
  overflow: hidden;
  display: flex;
}

/* .categories-inner-div-formargin-mp {
  width: 20%; 
  height: 100px;
  float: left;
  transform: rotateY(0deg);
  animation: rotate 10s linear infinite; 
}

@keyframes rotate {
  0% {
    transform: rotateY(0deg);
  }
  20% {
    transform: rotateY(-72deg);
  }
  40% {
    transform: rotateY(-144deg);
  }
  60% {
    transform: rotateY(-216deg);
  }
  80% {
    transform: rotateY(-288deg);
  }
  100% {
    transform: rotateY(-360deg);
  }
} */


.categories-inner-div-formargin-mp{
  margin-left: 20px;
  width: 20%;
}

.productImage_div {
  height: 200px;
  width: 100%;
  overflow: hidden;
}
.font_categories_class {
  font-size: 0.92rem;
}

@media Screen and (max-width: 1000px) {
  .categories-outer-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .categories-outer-div-mp {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .categories-inner-div {
    width: 33.33%;
  }

  .categories-inner-div-formargin-mp {
    width: 33.33%;
    margin-left: 0px;
  }
}

@media Screen and (max-width: 750px) {
  .categories-outer-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .categories-inner-div {
    width: 50%;
  }
  .categories-outer-div-mp {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .font_categories_class {
    font-size: 0.7rem;
  }
  .categories-inner-div-formargin-mp {
    width: 50%;
    margin-left: 0px;
  }
}
