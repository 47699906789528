.myFirstStyle {
  right: 48%;
  position: absolute;
  top: 9%;
  color: white;
  font-size: 15px;
  z-index: 1;
}

.mySecondStyle {
  right: 45%;
  max-height: 207px;
  position: absolute;
  top: 6%;
  height: 130px;
  z-index: 1;
}

.blue_container {
  background-color: #e2eef4;
  margin: 0;
  color: black;
}
.span_values {
  background-color: white;
  padding: 3px;
  color: black;
  border: 2px solid #015998;
  border-radius: 8px;
  margin: 0 0 0 8px;
  text-align: center;
  display: block;
}
.only_span_values {
  padding: 3px;

  border: 2px solid #e2eef4;

  margin: 0 0 0 8px;
}
.product_info_div {
  display: flex;
  height: 133px;
  width: 23rem;
}

.product_info_inner_div {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.values_margin {
  margin: 0 0 0 0;
  font-family: Poppins, sans-serif;
  font-weight: bold;
}
.text_color_class {
  color: white;
}

@media screen and (max-width: 1200px) {
  .mySecondStyle {
    right: 8%;
    top: 4.1%;
  }
  .myFirstStyle {
    right: 12%;
    top: 5.4%;
  }
}

@media screen and (max-width: 956px) {
  .myFirstStyle {
    right: 13%;
    top: 5.2%;
  }
}

@media screen and (max-width: 765px) {
  .myFirstStyle {
    right: 15%;
    top: 5.2%;
  }

  .mySecondStyle {
    height: 110px;
  }
}
