.modals {
  position: absolute;
  background-color: white;
  border: 1px solid grey;
  border-radius: 5px;
  overflow: auto;
  margin-top: -50rem;
  width: 550px;
  margin-left: 20rem;
  max-height: 500px;
  z-index: 1;
}

.f-modals {
  position: absolute;
  background-color: white;
  border: 1px solid grey;
  border-radius: 5px;
  overflow: auto;
  margin-top: -35rem;
  width: 550px;
  margin-left: 20rem;
  max-height: 500px;
  z-index: 1;
}

.headingModal {
  position: sticky;
  background-color: #015998;
  color: whitesmoke;
  margin-top: 8px;
}

.containerModal {
  display: flex;
  justify-content: center;
  margin-right: 25rem;
  z-index: -1;
}

.points {
  padding-top: 1rem;
  padding-left: 1rem;
}

.headerModal {
  display: flex;

 
  background-color: #015998;
  justify-content: center;
  align-items: center;
  
}
.close-btn-div {
  background-color: #015998;
  color: whitesmoke;
}
/* .close-btn{
    margin-left: 100px;
  } */
/* .headingDiv {
  /* width: 80%; */
  /* padding-left: 150px; */
/* } */ 
.privacyHeading {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
