.navbarnav{list-style:none;}
.navbarnav li{float:right; margin-top:20px; margin-left:10px;}
.navbarnav a{background: #015998; color:#fff; display:block; padding:8px 16px; font-size: 14px;}
.product_info a{background: #015998; border-radius: 10px; display: flex; align-items: center; justify-content: center; color: #fff; margin: 0 auto; text-align: center; padding: 11px 20px; max-width: 200px; font-size: 15px;white-space: normal!important; height:50px;}

.sctnhding{background: #015998; border-radius: 10px; display: inline-grid; align-items: center; justify-content: center; color: #fff; margin: 0 auto; text-align: center; padding: 11px 20px; width: 100%; font-size: 15px;white-space: normal!important; height:56px; font-weight:500; line-height: 18px;}
.single_bn_title1 {
  margin-top: 40px;
  text-align: center;
  margin-bottom: 30px;
  color: #000000;
  font-weight: 700;
}
.single_bn_link {
  background: #FFFFFF;
  border-radius: 10px;
  color: #000;
  font-weight: 700;
  margin: 0 auto;
  display: block;
  max-width: 250px;
  padding: 3px 15px;
  text-align: center;
  font-size: 19px;
}
.abtcont{background: #DBEEF0; padding:20px;  border-radius: 10px;}
.abtcont h2{font-size: 26px;   display: inline-block; 
text-align: center; padding: 3px 6px 6px 3px;  border-bottom:1px solid #000;
 color: #000; font-weight: 800;}
 .abtcont p{font-size: 15px; font-weight: 700; text-align:justify; margin-top:8px; margin-bottom:11px; line-height:1.5;} 
 .pb_40{padding-bottom:40px;}
 
 .top-header .navbarnav a i{display:none; }
 
 
 /* start head */
.header_wrap.fixed-top.inrpagehdr{border-top: 0px solid #01010C!important; border-bottom: 0px solid #01010C!important;} 
.top-headbg{background:#444444;}
.top-headbg .social_icons li a{background:#fff!important; color:#444444!important; height: 29px; width: 29px; line-height: 30px; font-size: 16px;}
.top-headbg .social_icons li a:hover{background:#444444!important; color:#fff!important;}
.logincartted{padding:0px; margin:0px; list-style:none;}
.logincartted li{float:right; padding:22px 0px 0px 20px; margin:0px; list-style:none;}
.logincartted li a{display:block; font-size:22px;}

.product_search_main{margin-top:18px;}
.product_search_main input{padding-left:48px; border-top-left-radius:10px!important; border-bottom-left-radius:10px!important;border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000;  background:#fff; height: 43px; font-size:15px;}
.product_search_main i{position:absolute; z-index:99; top: 14px; left: 18px;}
 .product_search_main input:focus{height: 43px!important;}
 .product_search_main .search_main{background: #015998; border: 1px solid #015998; color:#fff; border-top-right-radius:10px!important; border-bottom-right-radius:10px!important;  font-size:14px; padding-left:15px; padding-right:15px; font-weight:600;}
  .product_search_main .search_main span{display:none;}
 .carousel-indicators button{ border-radius:40px!important;}
 .carousel-indicators [data-bs-target]{width: 16px!important; border:0px!important; background-color: #8C8282; height: 16px!important;}
 .carousel-indicators .active {  background-color: #015998;} 
 .carousel {position: relative;  padding-bottom: 56px;  margin-bottom: -10px;}
 .heading_tab_header h3{color: #524545; text-align:center; display:block; width: 100%;font-size: 25px;}
 
 .dotsign .nav_style3.owl-theme .owl-nav [class*="owl-"]{position:relative!important;top: 10px!important;}
 
 .stlenstyle .owl-nav{display:none!important;}
 .stlenstyle .owl-theme .owl-dots .owl-dot span {width: 16px!important; height: 16px!important;}
 .stlenstyle  .owl-theme .owl-dots .owl-dot span {background-color: #8C8282!important; border: 1px solid #8C8282!important;}
 .stlenstyle  .dot_style1.owl-theme .owl-dots .owl-dot.active span{background-color: #015998!important; border: 1px solid #015998!important;}
 .stlenstyle  .owl-theme .owl-nav.disabled + .owl-dots {margin-top: -5px;}
 .stlenstyle .sale_banner{padding: 0 0px;}
 
 .prodstyel .heading_tab_header h3{text-align:left!important;}
 .prodstyel .vall{color: #015998; font-weight: 600; text-decoration:none; border-bottom:1px solid #015998; float:right; font-size:18px; padding-top: 5px;}
  .prodstyel .vall:hover{color: #034371;border-bottom:1px solid #034371;}
  .prodstyel .product{box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.15); border-radius: 0px 0px 5px 5px; text-align:center;}
  .prodstyel  .sale_banner{margin-bottom: 15px;}
  .prodstyel .product_cont h5 a{color:rgba(0, 63, 96, 1); font-size:17px; padding-bottom:8px; border-bottom: 1px solid #C2C2C2; display:block;}
  .prodstyel .product_price .descr{color: #003F60; display:block; padding-top:8px; padding-bottom:8px;}
  .prodstyel .product_price .date{color: #003F60; display:block; padding-top:8px; padding-bottom:8px;}
  .prodstyel .product_price .pricesml{color: #000000; display:block; padding-top:8px; padding-bottom:6px;} 
  .prodstyel .product_price .pricebig{color: #000000; display:block; font-size:17px; padding-top:6px; padding-bottom:8px; font-weight:600;}
  .prodstyel .product_price .pricebigcart{color: #fff; display:block; font-size:17px; padding-top:8px; padding-bottom:8px; background: #015998; border-radius: 0px 0px 5px 5px; font-weight:600; margin-left: -5px; margin-right: -5px;}
.prodstyel .product_price .pricebigcart:hover{color: #fff; background: #034371;}
.prodstyel .owl-theme .owl-nav [class*="owl-"] {   background-color: #034371; color: #fff;width: 50px !important;border-radius: 50px !important;}

.prodcatl .product{box-shadow: none!important;   border-radius: 0px!important;}
.prodcatl .sale_banner{box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.15);   border-radius: 0px 0px 5px 5px;}
.prodcatl .product_cont h5 a{color: #000000!important; border-bottom:0px!important;}
.prodcatl .product_cont h5 a:hover{color: rgba(0, 63, 96, 1)!important;}

.marg-btm30{margin-bottom:30px!important;}
 /* new db end */ 
 
 @media screen and (max-width:767px){
/* .top-header .col-md-3{width:18%!important;}
.top-header .col-md-5{width:55%!important;}
.top-header .col-md-4{width:27%!important; } */
.top-header .navbarnav a{padding: 4px 3px!important; border-radius:50px; line-height: 27px; display:block; height:27px; width:27px; }
.top-header .navbarnav li {margin-left: 3px!important;}
.top-header .navbarnav a span{display:none; }
 .top-header .navbarnav a i{display:block;  line-height: 20px;font-size: 14px!important; text-align: center;}
 .product_search_main .search_main{font-size:0px;}
 .product_search_main .search_main span{display:block; font-size:14px;}
 .social_icons li{padding-right:2px!important;}
.social_icons li a{font-size: 14px; height: 27px; width: 27px; line-height: 27px;}

.logincartted li{float:right; padding:28px 0px 0px 14px; margin:0px; list-style:none;}
.logincartted li:last-child{padding-left:0px!important;}
.logincartted li a{display:block; font-size:18px;}
.product_search_main i{left: 8px;}
.product_search_main input {padding-left: 31px;}
.product_search_main .search_main{    padding-left: 10px;     padding-right: 10px;}

}

/* Css By Pavan */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
.MuiGrid-item {
  padding-top: 10px !important;
}

legend {
  width: 0;
}


.owl-theme .owl-dots .owl-dot span {
  width: 16px !important;
  height: 16px !important;
  margin: 5px 5px !important;
  background: #8C8282;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}
.owl-nav button i{ font-size: 30px;}

.owl-dots button{ border-radius:40px!important;}
/* .owl-dots [data-bs-target]{width: 16px!important; border:0px!important; background-color: #8C8282; height: 16px!important;} */
.owl-dots .active span {  background-color: #015998 !important;} 

.hot_seller .sale_banner,.product-lists .sale_banner {
  margin-bottom: 15px;
  height: 210px;
  padding-top: 15px;
}
.prodstyel .product_cont h5 a{
  min-height: 50px;
}
.product-lists .product_price .pricebigcart {
  margin-left:0px !important;
  margin-right:0px !important;
}

.product .sale_banner img{
  max-height: 210px;
}
/* Product detail page */
.prod-detail-thumb{
  max-height: 560px;
  background: #F4F3F3;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  padding: 10%;
}
.prod-detail-thumb img{
  max-height: 450px;
  margin: 0px auto;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15));
  display: block;
}
.prod-detail .price{
   padding: 0px 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 42px;
  line-height: 63px;
  color: #000000;
}

.prod-detail p {
  line-height: 1;
}
.prod-detail .avail-qty{
  display: inline-block;
  padding: 5px 20px;
}

.prod-detail .sel-detail{
  background: #015998;
  padding: 10px;
  margin: 15px 0px;
  color: #FFF;
}

.btn-fill-out::before, .btn-fill-out::after {
  background-color: #015998;
}

.client_logo .cl_logo img {
  width: 163px !important;
}

.brand_logo .owl-stage-outer{
margin-bottom: 30px;
}

.hint-pre img{
  position: absolute;
  width: 65px !important;
  height: 65px;
  right: -10px;
  top: 0px;
  /* background: #0A9AE3; */
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); */
}
.hint-pre span{ 
  position: absolute;
  top: 10px;
  right: 1%;
  color: #FFF;
  font-weight: 500;
}

/* order/Checkout page */
.order_page .order_summary{
  background: #ECFEFF;
  padding: 30px;
  /* height: 300px; */
}

.order_page h1 {
  font-size: 35px;
  margin-bottom: 30px;
}
.order_page h2{
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: #003F60;
}
.order_page p{
  margin: 0px;
  /* font-size: 16px; */
}
.summary-list-price{
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #FC0C0C;
}
.summary-list-price-discard{
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  text-decoration-line: line-through;
  color: #484848;
}
.os-list-img{
  min-height: 110px;
}
.order_page .row-highlighter{
  background: #015998;
  margin: 20px 0px;
}

.order_page .row-highlighter td{
  color:#FFF;
  padding: 10px;
}
.order_page .row-highlighter td:first-child { 
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
 }
.order_page .row-highlighter td:last-child { 
  border-top-right-radius: 5px; 
  border-bottom-right-radius: 5px;
}
.order_page .total-payable .price-text{
  font-size: 17px;
  width: 100%;
  display: block;
}
.order_page .total-payable .price{
  width: 100%;
  font-weight: 600;
  font-size: 22px;
  line-height: 23px;
  text-align: center;
  color: #000000;
}
.proceed-order{
  font-weight: 600;
  font-size: 20px;
}
 .dropdown-menu{
  left: -135px;
  display: inline-block;
  top: 30px;
 }