.login-register-header-div {
  display: flex;
  justify-content: space-between;
  width: 290px;
}

.socialmedia-header-div {
  display: flex;
  justify-content: space-around;
  width: 160px;
}

.profile-header-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 5%;
}

.search-header-div {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 1% 0 1% 0;
}

.hamburger-menu {
  width: 31px;
  cursor: pointer;
}

.menu-items-header {
  background: #015998;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  z-index: 2;

  width: 56%;
  left: 44%;
  top: 3.5rem;
}

.menu-items-header-2 {
  background: white;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2;
  width: 20%;
  left: 79%;
  top: 3.5rem;
  height: 4rem;
}

.searchInput {
  padding-left: 30px;
  background: white;
  width: 27rem;
  height: 2rem;
  border: 2px solid #015998;
  border-radius: 14px;
  /* z-index: 1; */
}

.heganNameLogo {
  margin: 0 24% 0 0;
  width: 20%;
}

.buttonWithBorderRadius {
  border-radius: 5px;
}

/* Inside your CSS file or styling block */
.searchInput::placeholder {
  color: lightgray;
  font-size: small;
}

@media screen and (min-width: 800px) and (max-width: 1150px) {
  .login-register-header-div {
    display: flex;
    justify-content: space-around;
    width: 520px;
  }
}

@media screen and (max-width: 800px) {
  .hide-this {
    display: none;
  }
  .search-header-div {
    margin: 0.2% 1%;
  }

  .heganNameLogo {
    margin: 0 0 0 0;
    width: 23%;
  }

  .searchInput {
    /* padding-left: 30px; */
    background: white;
    width: 300px;
    height: 2rem;
    border: 2px solid #015998;
    border-radius: 14px;
  }
}
