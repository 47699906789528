.login_register_wrap{
  padding: 30px;
}
.login_mobile_no input{
  font-weight: 500;
  padding: 15px 15px;
}
.login_mobile_no{
  border: 0px !important;
}
 
.login_mobile_no>div{
  border-radius: 10px;
}
.login_mobile_no>div:before, .login_mobile_no>div:hover , .login_mobile_no>div:after{
  border-bottom: 0px !important;
 
}
.login_mobile_no:hover{
  border-bottom: 0px !important;
}
.login_wrap .resend-wrap{
  width: 100%;
    text-align: right;
    display: inline-table;
}
.login_wrap .resend-otp-btn{
  float: right;
  font-weight: 600;
  font-size: 16px;
}


.swal-text{
  text-align: center;
}