.formulation_flexbox {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: space-evenly;
  padding: 0 8.7% 0 9%;
}
.main_formulation_div {
  background-image: url(formularion_blue_bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;

  display: flex;
  align-items: center;
  text-align: center;
  font-size: 15px;
  justify-content: center;
  width: 200px;
  min-height: 130px;
  /* flex-grow: 1.5; */
  border-radius: 7px;
  cursor: pointer;
}

.searchFormInput {
  padding-left: 30px;
  background: white;
  width: 33%;
  height: 2rem;
  border: 2px solid #015998;
  border-radius: 14px;
  /* z-index: 1; */
}
.searchFormInput::placeholder {
  color: lightgray;
  font-size: small;
}

.search_icon_formulation {
  font-size: 1.2rem;
  color: rgb(1, 89, 152);
  position: absolute;
  right: 64.5%;
}

@media screen and (max-width: 460px) {
  .formulation_flexbox {
    gap: 7px 12px;
  }
  .main_formulation_div {
    max-width: 107px;
    font-size: 10px;
    min-height: 70px;
  }
}
@media screen  and (min-width: 461.99px) and (max-width: 508.99px) {
    .formulation_flexbox {
      gap: 7px 12px;
    }
    .main_formulation_div {
      max-width: 107px;
      font-size: 10px;
      min-height: 70px;
    }
  }

@media screen and (max-width: 800px) {
  .searchFormInput {
    /* padding-left: 30px; */
    background: white;
    width: 300px;
    height: 2rem;
    border: 2px solid #015998;
    border-radius: 14px;
  }
}

@media screen and (min-width: 800px) and (max-width: 1120px) {
  .search_icon_formulation {
    right: 63.5%;
  }
}

@media screen and (min-width: 743px) and (max-width: 799.99px) {
  .search_icon_formulation {
    right: 66%;
  }
}

@media screen and (min-width: 663px) and (max-width: 742.99px) {
  .search_icon_formulation {
    right: 68%;
  }
}

@media screen and (min-width: 604px) and (max-width: 662.99px) {
  .search_icon_formulation {
    right: 69.5%;
  }
}

@media screen and (min-width: 550px) and (max-width: 604.99px) {
  .search_icon_formulation {
    right: 72%;
  }
}

@media screen and (min-width: 497px) and (max-width: 549.99px) {
  .search_icon_formulation {
    right: 74%;
  }
}
@media screen and (min-width: 446px) and (max-width: 496.99px) {
  .search_icon_formulation {
    right: 76.6%;
  }
}
@media screen and (min-width: 401px) and (max-width: 445.99px) {
  .search_icon_formulation {
    right: 79.6%;
  }
}
@media screen and (min-width: 365px) and (max-width: 400.99px) {
  .search_icon_formulation {
    right: 82.6%;
  }
}

@media screen and (min-width: 335px) and (max-width: 364.99px) {
  .search_icon_formulation {
    right: 86.3%;
  }
}

@media screen and (min-width: 276px) and (max-width: 334.99px) {
  .search_icon_formulation {
    right: 89.3%;
  }
}
