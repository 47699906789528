.au_topdiv {
  display: flex;
  justify-content: center;
  align-items: start;
  gap: 22px;
}

.au_img {
  border-radius: 14px;

  width: 33%;
}

@media screen and (max-width: 1150px) {
  .au_topdiv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    gap: 0;
  }

  .au_img {
    border-radius: 14px;

    width: 80%;
  }
}
